window.Sortable = require('sortablejs');
window.Swal = require('sweetalert2');
require('formBuilder');
window.php_strtotime = require('locutus/php/datetime/strtotime');
window.php_isNumeric = require('locutus/php/var/is_numeric');
window.php_strlen = require('locutus/php/strings/strlen');
window.php_arrayDiff = require('locutus/php/array/array_diff');

window.Toast = window.Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});
